import Grid from '@mui/material/Grid'
import {
	Container,
	Title,
	Description,
	CtaContainer,
	Link,
	Button,
	HeroStyled,
} from '@/components/ui/organisms/alternative-banner/alternative-banner.styled'

const AlternativeBanner = ({
	aboveTheFold,
	hasVideo,
	title,
	titleProps,
	content,
	descriptionProps,
	cta,
	ctaProps,
	additionalContent,
	backgroundComponent,
	url,
	onClick,
	...props
}) => {
	const fullUrl = url ?? (cta && cta?.length === 1 ? cta[0].url : undefined)
	const fullOnClick = onClick ?? (cta && cta?.length === 1 ? cta[0].onClick : undefined)

	return (
		<HeroStyled
			overlayComponent={
				<Container horizontalAlign={props.horizontalAlign}>
					{title && (
						<Title variant="headlineMedium" {...titleProps}>
							{title}
						</Title>
					)}
					{content && (
						<Description variant="subheadline1" {...descriptionProps}>
							{content}
						</Description>
					)}
					{cta && cta?.length > 0 && (
						<CtaContainer
							display="inline-flex"
							justifyContent={props.horizontalAlign}
							hasSiblings={!!content || !!title}
							{...ctaProps}
						>
							{cta.map(({ label, url, variant = 'outlined', ...rest }, key) => (
								<Grid item key={key}>
									<Button href={url} variant={variant} {...rest}>
										{label}
									</Button>
								</Grid>
							))}
						</CtaContainer>
					)}
					{additionalContent}
				</Container>
			}
			backgroundComponent={
				<Link
					href={fullUrl}
					onClick={fullOnClick}
					sx={{
						cursor: fullUrl || fullOnClick ? 'pointer' : 'auto',
						height: aboveTheFold ? '100%' : undefined,
						display: hasVideo && aboveTheFold ? 'flex' : undefined,
					}}
				>
					{backgroundComponent}
				</Link>
			}
			{...props}
		/>
	)
}

export default AlternativeBanner
