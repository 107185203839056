import Typography from '@bluheadless/ui/src/atoms/typography'
import BHLink from '@bluheadless/ui/src/molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '@bluheadless/ui/src/molecules/button'
import { OverlayContainer } from '@bluheadless/ui/src/organisms/hero/hero.styled'
import Hero from '@bluheadless/ui/src/organisms/hero/hero'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo', 'colorHex', 'horizontalAlign'].includes(prop)
}

const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'right'
		case 'center':
			return 'center'
	}
}

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.black};
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`

const HeroStyled = styled(Hero)`
	${OverlayContainer} {
		position: relative;
		margin: ${({ theme }) => theme.spacing(2)};

		${({ theme }) => theme.breakpoints.down('md')} {
			text-align: left;
		}

		${({ theme }) => theme.breakpoints.up('md')} {
			position: absolute;
			bottom: inherit;
			margin: ${({ theme }) => theme.spacing(4, 0)};
			padding: ${({ theme }) => theme.spacing(0, 2.25)};
		}
	}
	&.title-alternative {
		${Title} {
			font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
		}
	}
`

const Container = styled('div', { shouldForwardProp })`
	display: flex;
	flex-direction: column;
	padding: 0;
	max-width: unset;
	text-align: ${({ horizontalAlign }) => textAlign(horizontalAlign)};
`

const Description = styled('div')`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	color: ${({ theme }) => theme.palette.primary.main};
	margin-bottom: ${({ theme }) => theme.spacing(3)};
	line-height: ${({ theme }) => theme.spacing(2)};
	.MuiTypography-root {
		font-size: inherit;
		line-height: inherit;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		line-height: ${({ theme }) => theme.spacing(3)};
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings, theme }) => (hasSiblings ? theme.spacing(0) : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const Button = styled(BHButton, { shouldForwardProp })`
	color: ${({ theme }) => theme.palette.primary.main};
	border-color: ${({ theme }) => theme.palette.primary.main};
	&:hover {
		color: ${({ theme }) => theme.palette.primary.main};
		border-color: ${({ theme }) => theme.palette.primary.main};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		color: ${({ colorHex }) => colorHex};
		border-color: ${({ colorHex }) => colorHex};
		&:hover {
			color: ${({ colorHex }) => colorHex};
			border-color: ${({ colorHex }) => colorHex};
		}
	}
	&.MuiButton-outlinedSecondary {
		background: unset;
		&:hover {
			background: ${({ theme }) => theme.palette.primary.main};
			color: ${({ theme }) => theme.palette.common.white};
			border-color: ${({ theme }) => theme.palette.primary.main};
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			background: unset;

			&:hover {
				background: ${({ theme }) => theme.palette.secondary.main};
				color: ${({ theme }) => theme.palette.primary.main};
				border-color: ${({ theme }) => theme.palette.secondary.main};
			}
		}
	}
	&.MuiButton-outlinedPrimary {
		background: unset;
		&:hover {
			background: ${({ theme }) => theme.palette.primary.main};
			color: ${({ theme }) => theme.palette.common.white};
			border-color: ${({ theme }) => theme.palette.primary.main};
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			background: unset;
		}
	}
`

export { HeroStyled, Container, Title, Description, CtaContainer, Link, Button }
